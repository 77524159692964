import React from 'react';
import panamericanLogo from 'assets/images/logos/panamerican-white.png';
import { useTranslation } from 'react-i18next';

interface LogoProps {
    haveSlogan: boolean;
}
export default function NV__logo({ haveSlogan = false }: LogoProps) {
    const { t } = useTranslation();
    return (
        <div className="nv__slider__logo">
            <img src={panamericanLogo} alt="Narval Logo" />
            {haveSlogan && (
                <div className="nv__slider__logo__slogan">
                    {t('logo.Logistics')} · {t('logo.Retail')} · {t('logo.Technology')} · Agro
                </div>
            )}
        </div>
    );
}
