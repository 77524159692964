import React from 'react';
import { useTranslation } from 'react-i18next';
import politicsImage from 'assets/images/home/politics/politics.jpg';
import Animated from 'components/generals/AnimatedComponent';

export default function Politics() {
    const { t } = useTranslation();
    return (
        <>
            <Animated animation={'animate__fadeInUp '}>
                <main
                    className="nv__section nv__politics nv__imageInfo nv__imageInfo--padding bg-strong-blue-color text-white-color"
                    id="politics"
                >
                    <section className="nv__section__content">
                        <Animated animation={'animate__fadeInUp'}>
                            <h1 className="nv__section__title nv__section__title--has-description text-center"></h1>
                        </Animated>

                        <section className="nv__section__container">
                            <div className="nv__imageInfo__block nv__imageInfo__block--text">
                                <div className="nv__imageInfo__info ">
                                    <h4>{t('politics.title')}</h4>
                                    <p>
                                        <strong>PANAMERICAN LOGISTIC INC. </strong>
                                        {t('politics.paragraph_1')}
                                    </p>

                                    <p>{t('politics.paragraph_2')}</p>

                                    <p>{t('politics.paragraph_3')}</p>
                                </div>
                            </div>
                            <div className="nv__imageInfo__block nv__imageInfo__block--image--square ">
                                <img src={politicsImage} alt="culure" />
                            </div>
                        </section>
                    </section>
                </main>
            </Animated>
        </>
    );
}
