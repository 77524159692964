import React from 'react';
import { Modal } from 'antd';
import { setDisplayModal, setDisplayModalPM } from 'reduxStore/slices/contact.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';

import ContactForm from 'components/generals/forms/contact-panamerican';
export default function ContactModalPanamerican() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.contactSlice.displayModalPM);

    return (
        <Modal
            width={992}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                dispatch(setDisplayModalPM(false));
            }}
        >
            <main className="nv__modal">
                <section className="nv__modal__content">
                    <ContactForm />
                </section>
            </main>
        </Modal>
    );
}
