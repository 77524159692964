import React from 'react';

import { FaHandshake, FaLinkedin } from 'react-icons/fa';
import { MdOndemandVideo } from 'react-icons/md';
import { BiRss } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { setDisplayModal, setVideoSize } from 'reduxStore/slices/component.slice';
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks';

export default function AliancesMeu() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <section className="nv__alliances__menu">
            <div
                className="nv__alliances__menu__item"
                onClick={() => {
                    dispatch(setDisplayModal(true));
                    dispatch(setVideoSize('nv__video__full'));
                }}
            >
                <Tooltip placement="rightBottom" title={'Video'}>
                    <MdOndemandVideo />
                </Tooltip>
            </div>
        </section>
    );
}
