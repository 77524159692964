import React, { useEffect } from 'react';
import MainSlider from 'components/generals/main-slider';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function PreloadPage() {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full nv__preload">
                <MainSlider logoSlogan={false} />

                {/* <section className="nv__preload__footer__menu">
                    <ul className="nv__preload__menu">
                        <li className="menu__item">
                            <Link to="#">{t('preload.logistics')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="#">PRS</Link>
                                </li>

                                <li>
                                    <Link to="#">METRO</Link>
                                </li>

                                <li>
                                    <Link to="#">PTYSS</Link>
                                </li>

                                <li>
                                    <Link to="#">HSC</Link>
                                </li>

                                <li>
                                    <Link to="/ptg">PTG</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu__item">
                            <Link to="#">{t('preload.technology')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="#">PANAMERICAN LOGISTICS INC</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu__item">
                            <Link to="#">{t('preload.agro')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="#">DONNA STELLA</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu__item">
                            <Link to="#">{t('preload.retail')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="#">BEERMARKT</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section> */}
            </main>
        </>
    );
}
