import React, { useState } from 'react';
import { Drawer } from 'antd';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import headerLogo from 'assets/images/logos/panamerican-white.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setDisplayModalPM } from 'reduxStore/slices/contact.slice';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { AppstoreOutlined, BranchesOutlined, DotChartOutlined } from '@ant-design/icons';
import { PiPlantFill } from 'react-icons/pi';
import { MdSell } from 'react-icons/md';

export default function MenuMovil() {
    const dispatch = useAppDispatch();
    const displayMobileMenu = useAppSelector((state) => state.contactSlice.displayMobileMenu);
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    type MenuItem = Required<MenuProps>['items'][number];

    const items: MenuItem[] = [
        {
            key: 'item1',
            label: <Link to="/"> {t('menu.home')}</Link>,
        },
        {
            key: 'item2',
            label: <Link to="/services"> {t('menu.services')}</Link>,
        },
        {
            key: 'item3',
            label: <Link to="/about"> {t('menu.about')}</Link>,
        },
        {
            key: 'item4',
            label: <Link to="/politics"> {t('menu.politics')}</Link>,
        },
        {
            key: 'item5',
            label: (
                <Link
                    to="#"
                    onClick={() => {
                        onClose();
                        dispatch(setDisplayModalPM(true));
                    }}
                >
                    {' '}
                    {t('menu.contact')}
                </Link>
            ),
        },
    ];

    const onClose = () => {
        dispatch(setDisplayMobileMenu(false));
    };

    return (
        <Drawer title="Basic Drawer" onClose={onClose} open={displayMobileMenu}>
            <div
                className="nv__header__logo nv__header__logo--mobile"
                onClick={() => {
                    onClose();
                }}
            >
                <Link to="/#home">
                    <img src={headerLogo} alt="Logo Narval white blue" />
                </Link>
            </div>

            <section>
                <Menu
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                    items={items}
                    onClick={() => {
                        onClose();
                    }}
                />
            </section>
        </Drawer>
    );
}
