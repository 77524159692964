
import { message } from 'antd';
import * as yup from 'yup';
export const contactFormSchema = yup
    .object({

        name: yup
            .string()
            .required('Este campo es requerido'),
        company: yup
            .string()
            .required('Este campo es obligatrorio'),    
        
        email: yup
            .string()
            .required('Este campo es obligatrorio')
            .email('Debes introducir un correo valido'),

        message: yup
            .string()
            .required('Este campo es obligatrorio'),
    })
    .required();
