import React, { useEffect } from 'react';
import MainSlider from 'components/generals/main-slider';
import MisionVision from 'pages/home/mission-vision';
import ServicesSection from 'pages/home/services';
import LogisticSection from 'pages/home/logistic';
import ContactSection from 'pages/home/contact';
import { useLocation } from 'react-router-dom';
import About from 'pages/home/about';
import Politics from 'pages/home/politics';
export default function HomePage() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full nv__home__page">
                <MainSlider logoSlogan={false} />
                <ServicesSection />
                <MisionVision />
                <About />
                {/* <LogisticSection /> */}
                {/* <RetailSection /> */}
                <Politics />
                <ContactSection />
            </main>
        </>
    );
}
