import React from 'react';
import ActionButton from 'components/ui/buttons/action-button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal, setDisplayModalPM } from 'reduxStore/slices/contact.slice';
import Animated from 'components/generals/AnimatedComponent';
export default function ContactSection() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    return (
        <>
            <Animated animation={'animate__fadeIn'}>
                <main className="nv__contact__section">
                    <section className="nv__section__container">
                        <div className="nv__overlay nv__overlay--dark-5">
                            <div className="nv__contact__section__info">
                                {/* <h1>{t('contact.message')}</h1> */}

                                <ActionButton
                                    text={t('contact.button')}
                                    onClick={() => {
                                        dispatch(setDisplayModalPM(true));
                                    }}
                                    type="rounded"
                                />
                            </div>
                        </div>
                    </section>
                </main>
            </Animated>
        </>
    );
}
