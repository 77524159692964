'use client';

import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import { useLocation } from 'react-router-dom';
import sliderImg1 from 'assets/slider/sliderImage_1.jpg';
import sliderImg2 from 'assets/slider/sliderImage_2.jpg';
import sliderImg3 from 'assets/slider/sliderImage_3.jpg';
import sliderImg4 from 'assets/slider/sliderImage_4.jpg';
import sliderImg5 from 'assets/slider/sliderImage_5.jpg';
import SliderItem from 'components/generals/main-slider/slider-item';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css';

interface SliderProps {
    logoSlogan: boolean;
}
export default function MainSlider({ logoSlogan }: SliderProps) {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);

    return (
        <section className="nv__slider nv__section" id="home">
            <Swiper
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                effect={'fade'}
                loop={true}
                navigation={false}
                pagination={{ clickable: true }}
                speed={1500}
                modules={[Autoplay, Navigation, Pagination, EffectFade]}
            >
                <SwiperSlide>
                    <SliderItem type="image" itemSrc={sliderImg1} logoSlogan={logoSlogan} />
                </SwiperSlide>

                <SwiperSlide>
                    <SliderItem type="image" itemSrc={sliderImg2} logoSlogan={logoSlogan} />
                </SwiperSlide>

                <SwiperSlide>
                    <SliderItem type="image" itemSrc={sliderImg3} logoSlogan={logoSlogan} />
                </SwiperSlide>

                <SwiperSlide>
                    <SliderItem type="image" itemSrc={sliderImg4} logoSlogan={logoSlogan} />
                </SwiperSlide>

                <SwiperSlide>
                    <SliderItem type="image" itemSrc={sliderImg5} logoSlogan={logoSlogan} />
                </SwiperSlide>
            </Swiper>
        </section>
    );
}
