import { configureStore } from "@reduxjs/toolkit";
import calculatorSlice from "./slices/calcutator.slice";
import contactSlice from "./slices/contact.slice";
import postSlice from "./slices/post.slice";
import componentSlice from "./slices/component.slice";
import { postApi } from "./services/post.services";


export const store = configureStore({
    reducer: {
        calculatorSlice,
        contactSlice,
        postSlice,
        componentSlice,
        [postApi.reducerPath]: postApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([
            postApi.middleware,
        ]),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
